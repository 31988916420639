/* eslint-disable react-hooks/exhaustive-deps */
import MainComposition from '@/components/mainComposition';
import { useWindowSize } from '@/hooks';
import Error404Page from '@/pages/404';
import {
  LayoutDataPage,
  LocalStorageKey,
  setRootFunctions,
} from '@/services/constants';
import { getLayout } from '@/services/services';
import {
  BackgroundImage,
  Box,
  Center,
  Image,
  Loader,
  Stack,
} from '@mantine/core';
import convert from 'color-convert';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Feature from '@/components/feature';
import { useTranslation } from 'react-i18next';
import useTrans from '@/hooks/useTrans';

// import Carousel from '@/components/carousel/carouel'

export default function Home() {
  const trans = useTrans();

  const windowDimension = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);

  const [layout, setLayout] = useState<LayoutDataPage | null>(null);
  const [layoutDataPages, setLayoutDataPages] = useState<any[]>([]);
  const router = useRouter();
  const { id } = router.query;

  useEffect(() => {
    const oldTenant: string | null = localStorage.getItem(
      LocalStorageKey.TENANT
    );
    let hostNameUrl: string = '';

    if (oldTenant) {
      const oldUrl = localStorage.getItem(oldTenant);
      hostNameUrl = oldUrl ? oldUrl : window.location.host;
    } else {
      hostNameUrl = window.location.host;
    }

    getLayout(hostNameUrl).then((layoutFromApi) => {
      if (
        layoutFromApi &&
        layoutFromApi.layout &&
        Array.isArray(layoutFromApi.layout.pages) &&
        layoutFromApi.layout.pages.length > 0
      ) {
        setLayoutDataPages(layoutFromApi.layout.pages);
        setRootFunctions(layoutFromApi.layout.config);
        setLayout({
          url: hostNameUrl,
          page: undefined,
          config: layoutFromApi.layout.config,
          headers: layoutFromApi.layout.headers,
          footers: layoutFromApi.layout.footers,
          tenant: layoutFromApi.tenant,
        });
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (layout && layoutDataPages.length > 0) {
      const copyLayout = { ...layout };
      copyLayout.page = layoutDataPages.find(
        (page) => page.href === `/${id}`
      );
      console.log('copyLayout', copyLayout);
      setLayout({ ...copyLayout });
      setIsLoading(false);
    }
  }, [layoutDataPages, router.asPath]);

  let backgroundColor = '#fff';
  if (layout?.config.primaryColor) {
    const convertColor = convert.hex.rgb(layout?.config.primaryColor || '');
    backgroundColor = `rgba(${convertColor[0]}, ${convertColor[1]}, ${convertColor[2]},0.1)`;
  }

  const featureContent = (
    <Stack
      spacing={0}
      style={{
        background: layout?.config.background || '#F2F6F9',
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {layout?.page?.body?.map((ele: any, index: number) => {
        const featureJSX = (
          <div
            style={{
              width: windowDimension.width <= 1560 ? '100%' : 1440,
              ...ele.options?.style,
            }}
          >
            <Feature
              url={layout?.url}
              display={ele.display}
              component={ele.component}
              options={ele.options}
              {...layout?.config}
              backgroundColor={backgroundColor}
              windowDimension={windowDimension}
              tenant={layout?.tenant}
            />
          </div>
        );

        if (ele.options?.backgroundImage) {
          return (
            <BackgroundImage
              key={index}
              src={ele.options?.backgroundImage}
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {featureJSX}
            </BackgroundImage>
          );
        }

        return (
          <div
            key={index}
            style={{
              background: ele.options.background,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {featureJSX}
          </div>
        );
      })}
    </Stack>
  );

  const mainContent = isLoading ? (
    <Center style={{ height: '100vh' }}>
      <Stack>
        {layout ? (
          <Center>
            <Image
              src={layout?.config.logo}
              alt='logo'
              style={{ maxWidth: 500 }}
            />
          </Center>
        ) : (
          <></>
        )}

        <Center>
          <Loader variant='dots' color={layout?.config.primaryColor} />
        </Center>
      </Stack>
    </Center>
  ) : (
    <MainComposition
      display={'main-composition1'}
      config={layout?.config}
      headers={layout?.headers}
      footers={layout?.footers}
      mainContent={featureContent}
    />
  );

  return isLoading || (!isLoading && layout && layout.page) ? (
    <>
      <Head>
        <title>{layout?.page?.name}</title>
        <meta
          name='description'
          content={layout?.page?.description || 'Trang CMS'}
        />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href={layout?.config.favicon} />
      </Head> 
      <Box>{mainContent}</Box>
    </>
  ) : (
    <p>{'[lang]'}</p>
  );
//   return <p>{'[lang]'}</p>
}
